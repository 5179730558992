// loading
.loader-overlay {
  -lh-property: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 1);
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  opacity: 1;
  z-index: 999;
  pointer-events: none;
}
@media (max-width: 950px) {
  .loader-overlay {
      -lh-property: 0;
      height: calc(100%);
      width: 100%;
  }
}

.loader-overlay .rating-indicator, .search-overlay-inner .rating-indicator {
  float: none;
  display: inline-block;
  width: 65px;
  height: 36px;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  border: 4px solid #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 1px);
  -ms-transform: translate3d(-50%, -50%, 1px);
  transform: translate3d(-50%, -50%, 1px);
}

.rating-indicator {
  float: left;
  height: 18px;
  width: 32px;
  border: 2px solid #000000;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  position: relative;
  margin-right: 30px;
}

.loader-overlay .rating-indicator .fill-indicator.fill-indicator_1, .search-overlay-inner .rating-indicator .fill-indicator.fill-indicator_1 {
  left: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-name: battery1;
  animation-name: battery1;
}

.loader-overlay .rating-indicator .fill-indicator, .search-overlay-inner .rating-indicator .fill-indicator {
  background: #d80061;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  width: 30%;
  zoom: 1;
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.rating-indicator .fill-indicator {
  background: #ffffff;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: width 250ms;
  transition: width 250ms;
}

.loader-overlay .rating-indicator .fill-indicator.fill-indicator_2, .search-overlay-inner .rating-indicator .fill-indicator.fill-indicator_2 {
  left: 35%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-name: battery2;
  animation-name: battery2;
}

.loader-overlay .rating-indicator .fill-indicator.fill-indicator_3, .search-overlay-inner .rating-indicator .fill-indicator.fill-indicator_3 {
  left: 70%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-name: battery3;
  animation-name: battery3;
}
@keyframes battery1 {
  0% {
    zoom: 1;
    opacity: 0;
  }
  25% {
    zoom: 1;
    opacity: 1;
  }
  50% {
    zoom: 1;
    opacity: 1;
  }
  75% {
    zoom: 1;
    opacity: 1;
  }
  100% {
    zoom: 1;
    opacity: 0;
  }
}
@keyframes battery2 {
  0% {
    zoom: 1;
    opacity: 0;
  }
  25% {
    zoom: 1;
    opacity: 0;
  }
  50% {
    zoom: 1;
    opacity: 1;
  }
  75% {
    zoom: 1;
    opacity: 1;
  }
  100% {
    zoom: 1;
    opacity: 0;
  }
}
@keyframes battery3 {
  0% {
    zoom: 1;
    opacity: 0;
  }
  25% {
    zoom: 1;
    opacity: 0;
  }
  50% {
    zoom: 1;
    opacity: 0;
  }
  75% {
    zoom: 1;
    opacity: 1;
  }
  100% {
    zoom: 1;
    opacity: 0;
  }
}