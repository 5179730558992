@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.calendarContainer {
  background: $grey;
  background-image: url(/static/images/background-repeat-tape-white.png);
  background-size: 7%;
  text-align: center;
  padding: 0 0 140px;
  margin-bottom: -1px;

  .title {
    font-size: 48px;
    margin: 0;
    padding: 0 0 20px;

    &.padding {
      padding-top: 80px;
    }
  }

  .yearsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .yearItem {
      margin: 0 10px;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $white;
      }
    }
  }

  .monthsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;

    .monthItemContainer {
      position: relative;

      .monthItem {
        position: relative;
        font-size: 48px;
        font-weight: bold;
        padding: 60px 25px;
        border-left: 1px solid $lightGrey;
        border-right: 1px solid $lightGrey;
        cursor: pointer;
        user-select: none;  
        transition: .5s all linear;
        
        &:hover {
          color: $pink;
        }
      }
      &.activeDesktop .monthItem {
        border-color: $white;
      }

      &.actual .monthItem {
        &:after {
          display: block;
          content: "Aktuální";
          position: absolute;
          font-size: 18px;
          bottom: -40px;
          transform: translateX(-50%);
          left: 50%;
        }
      }

      &.disabled {
        opacity: .3;
      }

      .arrow {
        display: none;
        user-select: none;   
      }
    }

  }

  @include media_max_xxl {
    .monthsContainer .monthItemContainer .monthItem {
      font-size: 30px;
    }
  }

  @include media_max_lg {
    .monthsContainer {
      margin-top: 50px;

      .monthItemContainer {
        .monthItem {
            display: none;
    
          }
        &.active .monthItem {
          display: block;
          border-color: $white;
        }

        &.active .arrow {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          &.arrowPrev {
            left: -80%;
            img {
              height: 40px;
            }
          }
          &.arrowNext {
            right: -80%;
            img {
              height: 40px;
            }
          }
        }
      }
    }
  }

  @include media_max_lg {
    .title {
      font-size: 36px;
    } 
  }

  @include media_hd {
    padding: 0 0 8vw;

    .title {
        font-size: 3vw;
        padding: 0 0 1vw;
    }

    .yearsContainer .yearItem {
        margin: 0 .5vw;
        font-size: 1vw;
    }
    .monthsContainer .monthItemContainer .monthItem {
        font-size: 2vw;
        padding: 3vw 1vw;
        border-width: .1vw;
    }

    .monthsContainer .monthItemContainer {
      &.actual .monthItem {
        &:after {
          font-size: .8vw;
            bottom: -2vw;
        }
      }
    }
  }
}