@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.detailHeaderContainer {
    display: flex;

    // .logo {
    //     position: absolute;
    //     left: 5%;
    //     top: 5%;
    //     cursor: pointer;
    //     z-index: 1;
        
    //     .logoIcon {
    //         width: auto;
    //         max-height: 45px;
    //     }
    // }

    .right, .right2, .left {
        display: block;
        width: 50%;
        background: $darkGrey;
    }

    .left {
        position: relative;
        overflow: hidden;

        .backgroundImage {
            background-repeat: no-repeat;
            //background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        
    }
    .content {
            padding: 7% 6% 7% 7%; 
        }
    .right, .right2 {
        padding: 7% 6% 7% 7%; 

        
    }

    h1 {
            font-size: 52px;
            margin: 0 0 30px;
            font-weight: bold;
        }

        h2 {
            font-size: 24px;
            font-weight: 400;
            margin: 0 0 30px;
        }

    a {
        color: $white;
    }

    @include media_max_lg {
        flex-direction: column;

        .logo {
            top: 20px;
          }

        .right, .right2, .left {
            width: 100%;
        }

        .right, .right2 {
            padding: 40px 30px 40px;

            
        }

        .content {
            padding: 40px 30px 40px;

            
        }

        .right2{
            min-height: 500px;
        }

        .left {
            min-height: 500px;

            .backgroundImage {
                background-position: top;
            }
        }

        h1 {
                margin-bottom: 0;
            }
            h2 {
                margin: 30px 0 0;
            }
            p {
                margin: 30px 0 20px;
            }
    }

    @include media_max_sm {
        .left, .right2 {
            min-height: 300px;
            margin-top: 20%;
        }
        h1 {
                font-size: 36px;
            }
    }

    @include media_hd {
        .logo .logoIcon {
            max-height: 2.5vw;
          }
        .right, .right2 {
            
        }

        h1 {
                font-size: 4vw;
                margin: 0 0 2vw;
            }
            h2 {
                font-size: 1.7vw;
                margin: 0 0 2vw;
            }
    }
}