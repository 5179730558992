@import "./variables.scss";

@mixin media_xs() {
  @media only screen and (min-width: $screen_xs) {
    @content;
  }
}

@mixin media_sm() {
  @media only screen and (min-width: $screen_sm) {
    @content;
  }
}

@mixin media_md() {
  @media only screen and (min-width: $screen_md) {
    @content;
  }
}

@mixin media_lg() {
  @media only screen and (min-width: $screen_lg) {
    @content;
  }
}

@mixin media_xl() {
  @media only screen and (min-width: $screen_xl) {
    @content;
  }
}

@mixin media_xxl() {
  @media only screen and (min-width: $screen_xxl) {
    @content;
  }
}

@mixin media_hd() {
  @media only screen and (min-width: $screen_hd) {
    @content;
  }
}

@mixin media_max_xxs() {
  @media only screen and (max-width: $screen_xxs) {
    @content;
  }
}

@mixin media_max_xs() {
  @media only screen and (max-width: $screen_xs) {
    @content;
  }
}

@mixin media_max_450() {
  @media only screen and (max-width: $screen_450) {
    @content;
  }
}

@mixin media_max_sm() {
  @media only screen and (max-width: $screen_sm) {
    @content;
  }
}

@mixin media_max_md() {
  @media only screen and (max-width: $screen_md) {
    @content;
  }
}

@mixin media_max_lg() {
  @media only screen and (max-width: $screen_lg) {
    @content;
  }
}

@mixin media_max_xl() {
  @media only screen and (max-width: $screen_xl) {
    @content;
  }
}

@mixin media_max_xxl() {
  @media only screen and (max-width: $screen_xxl) {
    @content;
  }
}

@mixin height_md() {
  @media only screen and (min-height: $screen_md) {
    @content;
  }
}

@mixin height_lg() {
  @media only screen and (min-height: $screen_lg) {
    @content;
  }
}

@mixin height_max_lg() {
  @media only screen and (max-height: $screen_lg) {
    @content;
  }
}
