@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.headerContainer {
    position: relative;
    overflow: hidden;
    background: $pink;
    
    &:hover {
      .backgroundImage {
        opacity: .5;
        transform: scale(1.1);
      }

      .button {
        background: $white;
        color: $pink;

        &:hover {
          background: $pink;
          color: $white;
        }
      }
    }
    
    .backgroundImage {
        background-repeat: no-repeat;
        height: 600px;
        background-position: center top;
        transition: all .5s ease-in-out;
        background-size: cover;
        transform: scale(1);
    }

    .logoContainer {
        position: absolute;
        top: 5%;
        left: 5%;
        display: flex;
        user-select: none;

        .logoImage {
            height: 180px;
            padding: 5px 5px 0 0;
        }

        .logoText {
            font-size: 62px;
            line-height: 100%;
            font-weight: bold;
            color: $black;
            b {
                color: $pink;
            }
            &.logoTextBlue {
              b {
                color: $blue;
              }
            }
        }
    }

    .titleContainer {
        position: absolute;
        top: 30%;
        left: 55%;

        h1, p, .button {
          display: block; 
          float: left; 
          clear: left; 
        }

        h1 {
            font-size: 52px;
            color: $black;
            margin: 0 0 10px;
            
            span {
              background: rgba($white, .8);
              padding: 5px 20px;
              display: inline-block;
              margin-bottom: 5px;
              float: left;
              clear: both;
            }
        }

        p {
            font-size: 16px;
            color: $white;
            background: rgba($black, .6);
            padding: 5px 20px;
        }

        &.blueVariant {
          h1 {
            color: $white;
            span {
              background: $grey;
            }
          }
          p {
            background: $blue;
          }
        }

        .button {
            margin-top: 10px;
        }
    }

    @include media_max_lg {
      .backgroundImage {
        height: 900px;
        background-size: cover;
        transform: scale(1);
      }

      &:hover {
        .backgroundImage {
          background-size: cover;
          transform: scale(1);
        }
      }
      .logoContainer {
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);

        .logoImage {
            height: 170px;
        }
        .logoText {
          font-size: 60px;
        }
      }

      .titleContainer {
        top: 340px;
        left: inherit;
        transform: inherit;
        display: flex;
        flex-direction: column;
        margin: 0 13vw;

        h1 {
            font-size: 60px;
            max-width: inherit;

            span {
              float: left;
              clear: left;
            }
        }

        p {
            font-size: 16px;
            margin: 0 auto 0 0;
        }
      }
    }

    @include media_max_md {
      .backgroundImage {
        height: 650px;
      }
      .logoContainer {
        .logoImage {
            height: 110px;
        }
        .logoText {
          font-size: 40px;
        }
      }
      .titleContainer {
        top: 240px;

        h1 {
            font-size: 36px;
            span {
              padding: 5px 10px;
            }
        }
        p {
            font-size: 14px;
        }
      }
    }

    @include media_max_sm {
      .titleContainer {
        width: 80%;
        margin: 0 10vw;

        h1 {
          font-size: 28px;
          margin: 0 0 15px;
        }
        p {
          padding: 5px 10px;
        }
      }
    }

    // bigger resolutions
    @include media_xxl {
      .backgroundImage {
        height: 800px;
      }
      .titleContainer {
        h1 {
          font-size: 56px;
        }
      }
    }
    @include media_hd {
      .backgroundImage {
        height: 39vw
      }

      .logoContainer {
        .logoImage {
          width: auto;
          height: 12vw;
          padding: .5vw .5vw 0 0;
        }

        .logoText {
          font-size: 4vw;
        }
    }

      .titleContainer {
        h1 {
          font-size: 3vw;
          margin: 0;
          padding: .4vw 0;
          max-width: 40vw;

          span {
            padding: .4vw 1vw;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 1vw;
          padding: .4vw .5vw;
          margin-bottom: .8vw;
        }
        .button {
          margin-top: 0vw;
        }
      }
    }
}