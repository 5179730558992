@import "./colors";
@import "./media";
@import "./fonts";
@import "./normalize";
@import "./loading";

html {
  font-weight: 400;
  height: 100%;
  font-size: 18px;
  scroll-behavior: smooth;
  color: $white;
  font-family: TeleNeo, Arial;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  position: relative;

  @include media_max_sm {
    padding: 0 20px;
  }
}

div,
span {
  box-sizing: border-box;
}

p {
  font-size: 16px;
  margin: 0;
  line-height: 180%;

  @include media_max_xxl {
    font-size: 14px;
  }
  @include media_max_sm {
    font-size: 12px;
  }

  @include media_hd {
    font-size: 1vw;
  }
}

// autofill input 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

// error page
.errorPage {
  color: $black;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);

  h1 {
    margin: 40px auto;
    font-size: 24px;
  }

  a {
    color: $pink;
  }

  img {
    max-width: 120px;
  }

  @include media_max_md {
    height: calc(100vh - 260px);
  }
}

// recaptcha
.grecaptcha-badge { visibility: hidden; }

// cookie consent
.cookies {
  &-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .35);
    z-index: 21;
    justify-content: center;
    align-items: center;
    padding: 20px;

    a {
      color: $blue;
    }

    &-inner {
      background-color: $white;
      color: $black;
      max-height: 100%;
      max-width: 600px;
      width: 100%;
      padding: 0 15px;
      overflow: auto;
    }

    &-settings {
      display: none;

      .cookies-popup-label {
        display: block;
        position: relative;
        padding: 15px 30px;

        #cookies-back {
          font-size: 18px;
          color: white;
          position: absolute;
          left: 0;
          line-height: 18px;
          top: 20%;
          display: inline-block;
          width: 20px;
          height: 20px;
          background-color: #000;
          cursor: pointer;

          //-webkit-mask-image: url(../images/layout/chevron.svg);
          //mask-image: url(../images/layout/chevron.svg);
          //-webkit-mask-repeat: no-repeat;
          //mask-repeat: no-repeat;

          // background-image: url(../images/layout/chevron.svg);
          // background-size: contain;
          // background-repeat: no-repeat;
          // background-position: center;
        }
      }
    }

    &-intro {
      p {
        font-size: 12px;
        margin: 12px 0;
        line-height: 19px;

        &:first-of-type {
          font-size: 14px;
          //font-weight: 600;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .cookies-row {
        border: none;
      }

      #cookies-settings {
        color: $blue;
        text-align: center;
        margin: 0 0 10px;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-label {
      font-size: 24px;
      padding: 15px 0;
      text-align: center;
    }
  }

  &-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  &-row {
    border-top: 1px solid rgb(202, 202, 202);
    padding: 15px 0;
    font-size: 12px;
    line-height: 19px;

    > p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    > a {
      font-size: 12px;
      line-height: 19px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        display: block;
        width: 100%;
        padding: 15px;
        border: 1px solid $pinkDark;
        border-radius: 4px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        text-decoration: none;

        &:hover {
          background-color: rgb(230, 243, 255);
        }

        &:nth-of-type(2) {
          margin-left: 25px;
          background-color: $pink;
          color: rgb(255, 255, 255);

          &:hover {
            background-color: rgb(255, 255, 255);
            color: rgb(0, 0, 0);
          }
        }

        @media screen and (max-width: 400px) {
          font-size: 12px;
          padding: 10px;

          &:nth-of-type(2) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &-label {
    height: 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: rgb(0,0,0);
    font-size: 14px;
    line-height: 19px;
  }

  &-less {
    display: none;
  }

  &-description {
    font-size: 12px;
    line-height: 19px;

    a {
      color: $blue;
    }

    input {
      display: none;
    }

    label {
      display: inline-block;
      margin-top: 12px;
      color: $blue;
      font-size: 12px;
      cursor: pointer;

      svg {
        transform: rotate(90deg);
        height: 10px;
        width: 10px;
      }
    }

    input:checked {
      ~ label {
        .cookies-more {
          display: none;
        }

        svg {
          transform: rotate(270deg);
        }

        .cookies-less {
          display: inline-block;
        }
      }

      ~ p .cookies-description-more{
        display: inline;
      }

    }

    &-more {
      display: none;
    }
  }

  &-input {
    &-succes {
      color: rgb(0, 133, 55);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 14px;
      font-weight: 500;


      span {
        content: '';
        display: inline-block;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgb(0, 133, 55);
        width: 30px;
        height: 30px;
        margin-left: 5px;
        border-radius: 50%;
      }
    }

    &-toggle {
      input {
        display: none;
      }

      &-on {
        display: none;
      }

      label {
        color: rgb(117, 117, 117);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .cookies-input-slider {
          content: '';
          display: inline-block;
          position: relative;
          margin-left: 6px;
          top: 0px;
          left: 0px;
          width: 54px;
          height: 34px;
          border-radius: 1.03125rem;
          background: rgb(117, 117, 117);
          cursor: pointer;

          &:after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin: 5px;
            background: rgb(255, 255, 255);
            transition: all 0.2s ease 0s;
          }
        }
      }

      input:checked {
        + label {
          color: #000;

          .cookies-input-toggle-off {
            display: none;
          }

          .cookies-input-toggle-on {
            display: inline-block;
          }

          .cookies-input-slider {
            background: rgb(0, 133, 55);
            &:after {
              margin-left: 24px;
            }
          }
        }
      }
    }

  }
}