@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.formContainer {
    background: $darkGrey;
    text-align: center;
    padding: 70px 0;
    
    .title {
        font-size: 48px;
        margin: 0;
        padding: 0px 0 70px;
    }

    .innerContainer {
        display: flex;
        flex-direction: row;
        max-width: 800px;
        margin: 0 auto;
        justify-content: space-around;

        label {
            display: block;

            input {
                background: $darkGrey;
                border: none;
                border-bottom: 1px solid $white;
                color: $white;
                outline: none;
                min-width: 220px;
                font-size: 16px;
                padding: 8px 0;

                &::placeholder {
                    color: $white;
                }

            }
            &:nth-child(2) {
                input {
                    margin-top: 40px;
                }
            }

            .error {
                text-align: left;
                display: block;
                color: $pink;
                font-size: 12px;
                padding-top: 10px
            }
        }

        .emailSendedContainer {
            margin-bottom: 100px;
        }
    }

    .submitButton {
        display: flex;
        margin: 60px auto 0;
        font-size: 16px;
        color: $white;
        background: $pink;
        outline: none;
        border: none;
        padding: 18px 28px;
        align-items: center;
        flex-direction: row;
        transition: opacity 0.3s ease-out, box-shadow 0.3s ease, background-color 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: $pinkDark;
            box-shadow: 0 0 21px rgba($black, .3);
        }

        &:disabled, &[disabled] {
            opacity: .5;
            cursor: not-allowed;

            &:hover {
                background-color: $pink;
                box-shadow: inherit
            }
        }

        .line {
            display: block;
            width: 25px;
            height: 1px;
            background: $white;
            margin-left: 40px;
        }
    }

    @include media_max_sm {
        .title {
            padding-bottom: 10px;
            font-size: 36px;
        }

        .innerContainer {
            flex-direction: column;

            label {
                input, &:nth-child(2) input {
                    width: 280px;
                    margin-top: 30px;
                }
                .error {
                   text-align: center;
                }
            }
        }
    }

    @include media_hd {
        padding: 6vw 0 5vw;
        .title {
            font-size: 3.5vw;
            padding: 0px 0 4vw;
        }
        .innerContainer {
            max-width: 50%;

            label input {
                min-width: 15vw;
                font-size: 1vw;
                padding: .5vw 0;
            }
        }
        .submitButton {
            width: 8.5vw;
            height: 2.3vw;
            font-size: .8vw;
            padding: .8vw 1.2vw;
            margin-top: 4vw;

            .line {
                width: 1.2vw;
                margin-left: 2vw;
            }
        }
    }
}