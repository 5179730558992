@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.detailContentContainer {
    color: $black;
    
    .detailBlock {
        padding: 80px 0;
        border-bottom: 1px solid $doveGray;
        
        .inner {
            max-width: 1024px;
            margin: 0 auto;

            h1 {
                font-size: 52px;
            }

            h2 {
                font-size: 46px;
            }

            h3 {
                font-size: 38px;
                margin: 0 0 30px
            }

            .button {
                margin-top: 30px;
            }

            img, p img {
                height: auto;
                margin: 30px auto;
                display: block;
                max-width: 100%;
            }
            

            iframe {
                margin: 20px auto;
                display: block;
            }

            a {
                color: $pink
            }

            &.markdown {
                p {
                    margin-top: 20px;
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    @include media_max_lg {
        .detailBlock {
            padding: 80px 30px;
            .inner {
                h1 {
                    font-size: 36px;
                }
                h2 {
                    font-size: 32px;
                }
                h3 {
                    font-size: 28px;
                }
                img {
                    max-width: 100%;
                }
                iframe {
                    max-width: 100%;
                }
            }
        }
    }

    @include media_hd {
        .detailBlock {
            padding: 4vw 0;
            .inner {
                max-width: 60%;
                h1 {
                    font-size: 3.5vw;
                }
                h2 {
                    font-size: 3vw;
                }
                h3 {
                    font-size: 2.5vw;
                    margin-bottom: 2.5vw;
                }
                .button {
                    margin-top: 2vw;
                }
            }
        }
    }
}