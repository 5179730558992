@import "../../../styles/media.scss";
@import "../../../styles/colors.scss";

.btn {
  display: block;
  font-weight: 800;
  width: 200px;
  height: 50px;
  line-height: 100%;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease, background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
  
  &.btn-pink {
    color: $white;
    background-color: $pink;
    border: none;
    font-weight: 400;

    &:hover {
      background-color: $pinkDark;
      box-shadow: 0 0 21px rgba($black, .3);
    }

    &:disabled,
    &[disabled] {
      opacity: .5;
    }

    &.small {
      font-size: 14px;
      width: 140px;
      height: 35px;
    }
  }

  &.external {
    line-height: 300%;
    @include media_max_lg {
      line-height: 220%;
    }
  }

  @include media_max_lg {
    width: 160px;
    height: 40px;
    font-size: 16px;
    transition: none;

    &.btn-transparent {
      line-height: 46px;
    }
  }

  @include media_hd {
    width: 10vw;
    height: 2.5vw;
    font-size: 1vw;
    &.btn-pink.small {
      width: 7.5vw;
      height: 1.8vw;
      font-size: 0.65vw;
    }
    &.external {
      line-height: 220%;
    }
  }
}
