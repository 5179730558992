@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.title {
  transform: translate(0, -50%);
  color: $black;
  text-align: center;
  font-size: 42px;
  margin-bottom: 140px;

  @include media_max_sm {
    font-size: 36px;
    margin-bottom: 100px;
  }

  @include media_max_xs {
    margin-bottom: 60px;
  }

  @include media_hd {
    font-size: 3vw;
    margin-bottom: 10vw;
  }
}

.boxesContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  background: $darkGrey;
  background-size: 7%;
  margin-bottom: -50px;

  .box {
    width: 80%;
    margin: 0 10%;
    overflow: hidden;
    border: 2px solid $white;
    transform: translate(0, -50%);
    height: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background: $black;
    transition: all .5s ease-in-out;

    .backgroundImage {
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      transition: all .5s ease-in-out;
      background-size: cover;
      transform: scale(1);
    }

    &:hover {
      background: $pink;
      .backgroundImage {
        opacity: .5;
        transform: scale(1.1);
      }

      .button {
        background: $white;
        color: $pink;

        &:hover {
          background: $pink;
          color: $white;
        }
      }
    }

    .content {
      position: absolute;
      max-width: 90%;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);

      .boxTitle {
        font-size: .8rem;
        margin-top: 0;
        min-height: 2.6rem;
      }

      .button {
        margin: 0 auto;
      }
    }
  }

  @include media_max_lg {
    grid-template-columns: 1fr;

    .box {
      .backgroundImage { 
        background-size: cover;
        transform: scale(1);
      }
      &:hover {
        .backgroundImage {
          background-size: cover;
          transform: scale(1);
        }
      }

    }

    .box {
      margin: 0 10% 5%;
    }
  }

  @include media_max_sm {
    margin-bottom: -80px;

    .box {
      height: 150px;
      .content {
        .boxTitle {
          font-size: .7rem;
        }
      }
    }
  }

  @include media_xxl {
    .box {
      height: 250px;
    }
  }

  @include media_hd {
    margin-bottom: -6vw;
    .box {
      height: 14vw;

      .content .boxTitle {
        font-size: 1vw;
        min-height: 3.5vw;
      }
    }
  }
}