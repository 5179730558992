@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.fixedMenu {
  position: fixed;
  width: 100%;
  height: 85px;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 11;
  visibility: hidden;
  transition: all .5s ease-in;
  top: -90px;
  pointer-events: none;

  &.show {
    top: 0;
    visibility: visible;
  }

  &.scrolledAfter {
    visibility: visible;
    top: -90px;
  }

  &.openFixed {
    background: transparent;

    .logo {
      display: none;
    }
  }

  &.scrolledDown {
    top: 0;
  }

  .menuButton {
    top: 20px;
    pointer-events:initial;
  }

  .logo {
    position: absolute;
    left: 5%;
    top: 20px;
    cursor: pointer;
    z-index: 1;
    pointer-events:initial;
    
    .logoIcon {
        width: auto;
        max-height: 45px;
    }
}
}

// MENU BUTTON
.menuButton {
  position: absolute;
  top: 20px;
  right: 5%;
  display: flex;
  width: 40px;
  height: 40px;
  background: $pink;
  border-radius: 60px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  align-items: center;
  justify-content: center;
  z-index: 11;

  &.menuButtonFixed {
    position: fixed;
  }

  .menuIcon {
      display: block;
      width: 20px;
      padding-right: 3px;
  }

  &:hover {
      box-shadow: 0 0 21px rgba($black, .3);
  }

  // MENU ICON
  .navIcon {
    width: 16px;
    height: 16px;
    position: relative;
    margin: 4px auto 0;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    cursor: pointer;
  }

  .navIcon.navIconOpen {
    width: 26px;
  }

  .navIcon span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: $white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  .navIcon span:nth-child(1) {
    width: 60%;
    top: 0px;
    left: 40%
  }
  
  .navIcon span:nth-child(2),.navIcon span:nth-child(3) {
    width: 80%;
    top: 5px;
    left: 20%;
  }
  
  .navIcon span:nth-child(4) {
    top: 10px;
  }
  
  .navIcon.navIconOpen span:nth-child(1) {
    top: 16px;
    width: 0%;
    left: 50%;
  }
  
  .navIcon.navIconOpen span:nth-child(2) {
    transform: rotate(45deg);
    width: 100%;
    left: 0
  }
  
  .navIcon.navIconOpen span:nth-child(3) {
    transform: rotate(-45deg);
    width: 100%;
    left: 0
  }
  
  .navIcon.navIconOpen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  @include media_max_lg {
    top: 20px;
  }

  @include media_hd {
    width: 2vw;
    height: 2vw;

    .navIcon {
      width: 1vw;
      height: 1vw;

      span {
        height: .1vw;
      }

      span:nth-child(2),span:nth-child(3) {
        top: .3vw;
      }
      span:nth-child(4) {
        top: .6vw;
      }

      &.navIconOpen {
        width: 1vw;
      }
    }
  }
}

// MENU CONTAINER
.menuContainer {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background: $white;
  background-image: url(/static/images/background-repeat-tape-pink.png);
  background-size: 7%;
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  
  &.open {
    transition: visibility 0.5s, opacity 0.5s linear;
    visibility: visible;
    opacity: 1;
  }

  .logo {
    position: absolute;
    left: 5%;
    top: 20px;
    cursor: pointer;
    
    .logoIcon {
      width: auto;
      max-height: 45px;
    }
  }

  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 80%;
    margin: 0px auto;
    
    .menuItem {
      color: $black;
      font-size: 48px;
      margin: 20px auto;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media_max_lg {
    background-size: 20%;

    .logo {
      top: 20px;
    }

    .inner {
      margin-top: 0px;
      grid-template-columns: 1fr;
      
      .menuItem {
        margin: 12px auto;
        font-size: 40px;
      }
    }
  }

  @include media_max_md {
    .inner {
      margin-top: 40px;
      .menuItem {
        font-size: 32px;
      }
    }
  }

  @include media_hd {
    .logo .logoIcon {
      max-height: 2.5vw;
    }
    .inner {
      .menuItem {
        font-size: 4vw;
        margin: 1.5vw auto;
      }
    }
  }
}