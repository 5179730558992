@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.footerContainer {
  background: $darkGrey;
  border-top: 1px solid $white;

  .footerInner {
    display: flex;
    max-width: 1000px;
    min-height: 200px;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    position: relative;

    .logo {
      position: absolute;
      width: auto;
      max-height: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .socialIconsContainer {
      margin-left: 0;
      margin-right: auto;
      display: flex;

      img {
        filter: brightness(0) invert(1);
        margin-right: 20px;
        
        &:hover {
          filter: brightness(0) invert(1) grayscale(100%) brightness(58%) sepia(100%) hue-rotate(-58deg) saturate(1198%) contrast(1.1)
        }
      }
    }

    .copyright {
      text-align: right;
      padding-right: 20px;
    }

    @include media_max_md {
      flex-direction: column;
      justify-content: center;
      min-height: 300px;

      .socialIconsContainer {
        margin-left: auto;
        margin-bottom: 40px;
      }

      .logo {
        position: relative;
        transform: none;
        left: inherit;
        top: inherit;
      }

      .copyright {
        padding-top: 50px;
        padding-right: 0;
      }
    }

    @include media_hd {
      height: 9vw;
      max-width: 75%;

      .logo {
        max-height: 3vw;
      }
      p {
        font-size: .8vw;
      }
    }
  }
}