@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.boxesContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: $grey;
  background-image: url(/static/images/background-repeat-tape-white.png);
  background-size: 7%;
  margin-bottom: -1px;

  &.grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.grid-3 {
    grid-template-columns: 2fr 1fr 1fr;
  }
  &.grid-2 {
    grid-template-columns: 2fr 2fr;
  }

  .box {
    width: 80%;
    margin: 0 10%;
    overflow: hidden;
    border: 2px solid $white;
    transform: translate(0, -50%);
    height: 180px;
    background: $black;
    transition: all .5s ease-in-out;

    .backgroundImage {
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      transition: all .5s ease-in-out;
      background-size: cover;
      transform: scale(1);
    }

    &:hover {
      background: $pink;
      .backgroundImage {
        opacity: .5;
        transform: scale(1.1);
      }

      .button {
        background: $white;
        color: $pink;

        &:hover {
          background: $pink;
          color: $white;
        }
      }
    }

    .content {
      position: absolute;
      max-width: 90%;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);

      .boxTitle {
        font-size: .8rem;
        margin-top: 0;
        min-height: 2.6rem;
      }

      .button {
        margin: 0 auto;
      }
    }
  }

  @include media_max_lg {
    grid-template-columns: 1fr 1fr;

    .box {
      .backgroundImage { 
        background-size: cover;
        transform: scale(1);
      }
      &:hover {
        .backgroundImage {
          background-size: cover;
          transform: scale(1);
        }
      }

    }


    &.grid-4, &.grid-3, &.grid-2 {
      grid-template-columns: 1fr 1fr;
    } 

    .box {
      margin: 5% 10%;
    }
  }

  @include media_max_sm {
    .box {
      .content {
        .boxTitle {
          font-size: .7rem;
        }
      }
    }
  }

  @include media_max_450 {
    grid-template-columns: 1fr;

    &.grid-4, &.grid-3, &.grid-2 {
      grid-template-columns: 1fr;
    } 
  }

  @include media_hd {
    .box {
      height: 12vw;

      .content .boxTitle {
        font-size: 1vw;
        min-height: 3.5vw;
      }
    }
  }
}