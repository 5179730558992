@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.filterContentContainer {
    background: $white;
    background-image: url(/static/images/background-repeat-tape-pink.png);
    background-size: 7%;
    text-align: center;
    padding: 120px 0 0;

    .logo {
        position: absolute;
        left: 5%;
        top: 20px;
        cursor: pointer;
        
        .logoIcon {
          width: auto;
          max-height: 45px;
        }
    }

    h3 {
        margin: 0 0 -20px;
        color: $black;
        font-size: 42px;
    }
    
    .boxesContainer {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    
      .box {
        width: 80%;
        margin: 20px 10%;
        overflow: hidden;
        border: 2px solid $white;
        transform: translate(0, 40%);
        height: 170px;
        background: $black;
    
        .backgroundImage {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: inherit;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: .5
        }
    
        .content {
          position: absolute;
          max-width: 90%;
          top: 50%;
          left: 50%;
          text-align: center;
          transform: translate(-50%, -50%);
    
          .boxTitle {
            font-size: .8rem;
            margin-top: 0;
            min-height: 2.6rem;
          }
    
          .button {
            margin: 0 auto;
          }
        }
      }
    }

    .divider {
        height: 180px;
        background: $darkGrey;
        margin-bottom: -1px;

        &.dividerWithoutBtn {
          height: 100px;

          .loadMoreContainer {
            display: none;
          }
        }

        .loadMoreContainer {
          padding-top: 120px;
        }
    }

    .boxesContainer .box .content a, .divider .loadMoreBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        transition: opacity 0.3s ease-out, box-shadow 0.3s ease, background-color 0.3s ease;
        text-align: center;
        text-decoration: none;
        color: $white;
        background-color: $pink;
        border: none;
        font-weight: 400;
        font-size: 14px;
        width: 140px;
        height: 35px;
        margin: 0 auto;

        &:hover {
          background-color: $pinkDark;
          box-shadow: 0 0 21px rgba($black, .3);
        }
    }

    @include media_max_lg {
        background-size: 20%;

        .logo {
          top: 20px;
        }
        
        h3 {
            margin-bottom: -50px;
        }
        .boxesContainer {
            grid-template-columns: 1fr 1fr;
        
            .box {
                margin: 5% 10%;
            }
        }
    }
    
    @include media_max_sm {
      h3 {
        font-size: 36px;
      }
      .boxesContainer {
          .box {
              .content {
                  .boxTitle {
                      font-size: .7rem;
                  }
              }
          }
      }
    }
  
    @include media_max_450 {
      .boxesContainer {
          grid-template-columns: 1fr
      }   
    }

    @include media_hd {
      h3 {
        font-size: 3vw;
      }
      .logo .logoIcon {
        max-height: 2.5vw;
      }
      .boxesContainer {
        .box {
          height: 11vw;
          margin: 1.8vw 10%;

          .content .boxTitle {
            min-height: 3.5vw;
            font-size: 1vw;
          }
        }
      }
    }
}
