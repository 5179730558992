@font-face {
  font-family: "TeleNeo";
  src: url("../fonts/TeleNeo-Regular.woff2") format("woff2"),
  url("../fonts/TeleNeo-Regular.woff") format("woff"),
  url("../fonts/TeleNeo-Regular.otf") format("otf");
}
// @font-face {
//   font-family: "Gotham";
//   font-style: normal;
//   font-weight: 400;
//   font-display: optional;
//   src: url("/static/fonts/Gotham-Book.woff2") format("woff2"),
//     url("/static/fonts/Gotham-Book.woff") format("woff");
// }
// @font-face {
//   font-family: "Gotham";
//   font-style: normal;
//   font-weight: 500;
//   font-display: optional;
//   src: url("/static/fonts/Gotham-Medium.woff2") format("woff2"),
//     url("/static/fonts/Gotham-Medium.woff") format("woff");
// }
// @font-face {
//   font-family: "Gotham";
//   font-style: normal;
//   font-weight: 700;
//   font-display: optional;
//   src: url("/static/fonts/Gotham-Bold.woff2") format("woff2"),
//     url("/static/fonts/Gotham-Bold.woff") format("woff");
// }
// @font-face {
//   font-family: "Gotham";
//   font-style: normal;
//   font-weight: 800;
//   font-display: optional;
//   src: url("/static/fonts/Gotham-Black.woff2") format("woff2"),
//     url("/static/fonts/Gotham-Black.woff") format("woff");
// }